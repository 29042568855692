import { lazy } from 'react';

const Map = lazy(() => import('../../../views/Map'));
const Presentation = lazy(() => import('../../../views/Presentation'));
const Glossary = lazy(() => import('../../../views/Glossary'));
const Contact = lazy(() => import('../../../views/Contact'));
const FAQ = lazy(() => import('../../../views/FAQ'));

const Viewpoint = lazy(() => import('../../../views/Viewpoint'));
const Profile = lazy(() => import('../../../AppLayout/Profile'));

const routes = [
  {
    Component: Map,
    path: '/',
    exact: true,
  },
  {
    Component: Presentation,
    path: '/presentation',
    exact: true,
  },
  {
    Component: Glossary,
    path: '/glossary',
    exact: true,
  },
  {
    Component: Contact,
    path: '/contact',
    exact: true,
  },
  {
    Component: FAQ,
    path: '/FAQ',
    exact: true,
  },
  {
    Component: Viewpoint,
    path: '/viewpoint/:id/:imageView?',
  },
  {
    Component: Profile,
    path: '/create-account/:id/:token',
  },
];

export default routes;
